import React, { useState } from "react";

import { Link } from "gatsby";
import Star from "@components/Star";

import { css } from "@oddcommon/utils";
import styles from "./Footer.module.scss";

import { socialLinks, navLinks } from "@config";

export default function Footer() {
  const [newsletterSuccess, setNewsletterSuccess] = useState(false);
  const [newsletterErrorMessage, setNewsletterErrorMessage] = useState(null);

  return (
    <footer
      className={styles.base}
      data-page-background-color="var(--color-white)"
      data-page-is-dark={false}
    >
      <nav>
        <ul>
          {navLinks.map(({ name, path }) => (
            <li key={name}>
              <Link to={path}>{name}</Link>
            </li>
          ))}
        </ul>
      </nav>

      <div className={styles.contact}>
        <div className={styles.group}>
          <p className="eyebrow">New Business</p>
          <p className="large">
            <a href="tel:12125633939">+1 212 563 3939</a>
            <br />
            <a href="mailto:carson@bgstr.com">carson@bgstr.com</a>
          </p>
        </div>
        <div className={styles.group}>
          <p className="eyebrow">General inquiries</p>
          <p className="large">
            <a href="tel:12125633939">+1 212 563 3939</a>
            <br />
            <a href="mailto:contact@bgstr.com">contact@bgstr.com</a>
          </p>
        </div>
        <div className={styles.group}>
          <p className="eyebrow">Location</p>
          <p className="large">
            <a
              href="https://www.google.com/maps/place/547+West+27th+Street+%23310,+New+York,+NY+10001"
              target="_blank"
              rel="noopener noreferrer"
            >
              547 West 27th Street
              <br />
              Suite 310
              <br />
              New York, NY 10001
            </a>
          </p>
        </div>
      </div>

      <div className={css(styles.right)}>
        {newsletterSuccess ? (
          <p className={styles.newsletterSuccessMessage}>We’ll be in touch.</p>
        ) : (
          <form
            action="/api/mailchimp"
            method="post"
            noValidate
            className={css(newsletterErrorMessage && styles.error)}
            onSubmit={(e) => {
              e.preventDefault();
              fetch(e.target.action, {
                method: "post",
                body: new FormData(e.target),
              })
                .then(async (r) => {
                  const response = await r.json();
                  if (r.ok) {
                    return response;
                  } else {
                    throw response;
                  }
                })
                .then((data) => {
                  console.log("success", data);
                  setNewsletterSuccess(true);
                })
                .catch((data) => {
                  console.log("error", data);
                  // Set error messages
                  const messages = (data?.offenders || []).map((o) => o.err).filter((m) => m);
                  if (messages.length) {
                    setNewsletterErrorMessage(
                      messages.map((m) => `${m[0].toUpperCase()}${m.slice(1)}`).join("\n"),
                    );
                  } else {
                    setNewsletterErrorMessage("Something went wrong");
                  }
                });
            }}
          >
            <label htmlFor="b_email" className={styles.formLabel}>
              Sign up for our newsletter
            </label>
            <div>
              <input id="b_email" name="b_email" type="email" placeholder="Email Address" />
              <button type="submit">&rarr;</button>
            </div>
            <input type="hidden" name="mc_signupsource" value="hosted" />
            {newsletterErrorMessage && (
              <p className={styles.errMessage}>{newsletterErrorMessage}</p>
            )}
          </form>
        )}

        <ul className={styles.socialLinks}>
          {socialLinks.map(({ name, url }) => (
            <li key={name}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.bottomRow}>
        <span className={styles.copyright}>
          &copy; Copyright BigStar {new Date().getFullYear()}
        </span>
        <Star spec="sm" className={styles.star} />
      </div>
    </footer>
  );
}
