import React, { useState, useEffect, useCallback, useMemo, createContext, useRef } from "react";
import { getQueryVar } from "@oddcommon/utils";
import { base64encode, base64decode } from "@js-utils/base64";

export const PlaylistContext = createContext();

export const PlaylistProvider = ({ children }) => {
  const [currentPlaylist, setCurrentPlaylist] = useState([]);
  const currentPlaylistRef = useRef();
  currentPlaylistRef.current = currentPlaylist;

  // If there's a playlist in the query string, set that as the saved playlist
  useEffect(() => {
    let q = getQueryVar("playlist");
    if (q) {
      const projectList = base64decode(q).split("&");
      setCurrentPlaylist(projectList);
    }
  }, []);

  // Toggle the presence of a single item in the playlist
  const addRemovePlaylistItem = useCallback((item) => {
    if (currentPlaylistRef.current.includes(item)) {
      setCurrentPlaylist(currentPlaylistRef.current.filter((i) => i !== item));
    } else {
      setCurrentPlaylist([...currentPlaylistRef.current, item]);
    }
  }, []);

  return (
    <PlaylistContext.Provider
      value={useMemo(
        () => ({
          actions: { addRemovePlaylistItem },
          currentPlaylist,
          currentPlaylistCode: base64encode(currentPlaylist.join("&")),
        }),
        [addRemovePlaylistItem, currentPlaylist],
      )}
    >
      {children}
    </PlaylistContext.Provider>
  );
};
