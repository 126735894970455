import React from "react";
import PropTypes from "prop-types";

// For an “optically balanced” star we want the center point to be the center of the bounding
// rectangle, not the center of the circumcircle.
const rectangleHeight =
  0.5 + // vertical height of top point
  0.5 * Math.cos((2 * Math.PI) / 5 / 2); // vertical height of two bottom points
const circleHeight = 1;
const rectCircleRatio = rectangleHeight / circleHeight;

export default function Star({ spec = "sm", style, ...props }) {
  const attributes = {
    sm: { size: "0.8rem", stroke: "0.125rem" },
    md: { size: "3.5rem", stroke: "0.25rem" },
    lg: { size: "20rem", stroke: "0.75rem" },
  };

  const { size, stroke } = attributes[spec] || spec;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1 1"
      stroke="currentColor"
      strokeWidth={`${parseFloat(stroke) / parseFloat(size)}px`}
      {...props}
      style={{ width: size, height: size, ...style }}
    >
      {new Array(5).fill(null).map((_, i) => {
        const angle = ((2 * Math.PI) / 5) * i - Math.PI / 2;
        return (
          <line
            key={i}
            x1="0.5"
            y1={rectCircleRatio * 0.5}
            x2={Math.cos(angle) / 2 + 0.5}
            y2={Math.sin(angle) / 2 + 0.5}
          />
        );
      })}
    </svg>
  );
}

Star.propTypes = {
  spec: PropTypes.oneOfType([
    PropTypes.oneOf(["sm", "md", "lg"]),
    PropTypes.shape({ size: PropTypes.string.isRequired, stroke: PropTypes.string.isRequired }),
  ]),
  lineWidth: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};
