/* Handles switching page background color when */
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from "react";

import { useColorMeasurer } from "@js-utils/hooks";
import chroma from "chroma-js";

import BodyStyler from "./BodyStyler";
import FaviconDriver from "./FaviconDriver";

export const SectionColorContext = createContext();
export const useSectionColor = () => useContext(SectionColorContext);

const datasetAttributes = ["pageBackgroundColor", "pageAccentColor", "pageIsDark"];

// Recursively search parent nodes for SectionColor-related attributes
function getAttributes(target) {
  if (!target) return {};
  const parentAttrs = getAttributes(target.parentNode);
  // tail recursion ensures child nodes take precedence
  const sectionColorAttrs = Object.fromEntries(
    // SectionColor system defines the dataset attributes it finds relevant
    Object.entries(target.dataset || {}).filter(([k]) => datasetAttributes.includes(k)),
  );
  return { ...parentAttrs, ...sectionColorAttrs };
}

/* Handles switching body background color */
/* NOTE: should be installed once per page, not as part of a multi-page layout */

export default function SectionColorProvider({ children }) {
  const [ready, setReady] = useState(false);
  const [pageBackgroundColor, setPageBackgroundColor] = useState();
  const oldBackgroundColor = useRef();
  const [pageAccentColor, setPageAccentColor] = useState();
  const [pageIsDark, setPageIsDark] = useState();

  // Grab and store initial styles (usually provided by an InitialColorProvider on a page)
  // Once the initial styles are stored here, the InitialColorProvider will disable itself and the
  // BodyStyler that belongs to this component will take over.
  useEffect(() => {
    const styles = getComputedStyle(document.body);
    const bg = styles.getPropertyValue("--page-background-color");
    setPageBackgroundColor(bg);
    oldBackgroundColor.current = bg;
    setPageAccentColor(styles.getPropertyValue("--page-accent-color"));
    setPageIsDark(styles.getPropertyValue("--is-dark").trim() === "1");
    setReady(true);
  }, []);

  const [bgTransitionTime, setBgTransitionTime] = useState(0.8);

  const measureColor = useColorMeasurer();
  // Calculate how subtle background color shifts are and make more subtle shifts go more slowly
  useEffect(() => {
    const delta = chroma.distance(
      measureColor(oldBackgroundColor.current),
      measureColor(pageBackgroundColor),
    );
    const distanceFactor = (255 - delta) / 255;
    setBgTransitionTime(0.4 + distanceFactor * 0.2);

    oldBackgroundColor.current = pageBackgroundColor;
  }, [pageBackgroundColor, measureColor]);

  // Update with scroll position
  const updateColors = useCallback(() => {
    const centeredEl = document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2);
    const attrs = getAttributes(centeredEl);
    if (attrs.pageBackgroundColor) setPageBackgroundColor(attrs.pageBackgroundColor);
    if (attrs.pageAccentColor) setPageAccentColor(attrs.pageAccentColor);
    if (attrs.pageIsDark) setPageIsDark(attrs.pageIsDark === "true");
  }, [setPageBackgroundColor, setPageAccentColor, setPageIsDark]);
  useEffect(() => {
    window.addEventListener("scroll", updateColors, { passive: true });
    window.addEventListener("resize", updateColors, { passive: true });
    return () => {
      window.removeEventListener("scroll", updateColors);
      window.removeEventListener("resize", updateColors);
    };
  }, [updateColors]);

  // Keep track of accent color as rgba string
  const [measuredAccentColor, setMeasuredAccentColor] = useState();
  useEffect(() => {
    setMeasuredAccentColor(pageAccentColor && measureColor.rgbaString(pageAccentColor));
  }, [pageAccentColor, measureColor]);

  // Keep actions object between renders
  const actions = useMemo(
    () => ({
      setPageBackgroundColor,
      setPageIsDark,
      setPageAccentColor,
      refresh: updateColors,
    }),
    [setPageBackgroundColor, setPageIsDark, setPageAccentColor, updateColors],
  );

  return (
    <SectionColorContext.Provider
      value={{
        pageBackgroundColor,
        pageIsDark,
        pageAccentColor,
        actions,
        globalProviderReady: ready,
      }}
    >
      {children}

      {/* Used for measuring color values */}
      <measureColor.probe />

      {/*
        Let InitialColorProvider steal the show for the first render so that we have the right
        colors in the SSR render. Once the app mounts and we have a chance to grab those colors, we
        take things over, and the SectionColorProvider styles get precedence from there on out.
      */}
      {ready && (
        <>
          <BodyStyler
            styles={{
              pageBackgroundColor,
              pageAccentColor,
              bgTransitionTime,
              pageIsDark,
            }}
          />
          {measuredAccentColor && (
            <FaviconDriver color={measuredAccentColor} transitionTime={bgTransitionTime * 1.25} />
          )}
        </>
      )}
    </SectionColorContext.Provider>
  );
}
