/*
 * Provides timing info for build-in animations based on the position of the element on the page.
 * See material guidelines for “choreographing surfaces” (https://material.io/archive/guidelines/motion/choreography.html#choreography-creation)
 *
 *   “When multiple new surfaces are created at the same time, quickly stagger the appearance of
 *   each. Create a clear, smooth focal path in a single direction.”
 *
 * In service of this goal, the order in which we stagger elements follows a clear path from the top
 * left of the page to the bottom right of the page (with elements at the top left appearing first,
 * and elements at the bottom right appearing last).
 */

import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useImperativeHandle,
  forwardRef,
  createContext,
} from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { cubicBezier } from "popmotion";

import { StaggerTimingContext } from "./StaggerTimingProvider";
export const StaggeredBuildContext = createContext(StaggerTimingContext);

const StaggeredBuildEffect = forwardRef(function StaggeredBuildEffect(
  {
    el = "div",
    children,
    duration = 0.8,
    imposedDelay = duration * 0.15,
    extendTransition,
    variants,
    ...props
  },
  forwardedRef,
) {
  const { imposeDelay, getDelay } = useContext(StaggerTimingContext);

  // Always start hidden
  const [hide, setHidden] = useState(true);

  const baseRef = useRef();
  // baseRef is the ref that's exposed too
  useImperativeHandle(forwardedRef, () => baseRef.current);

  // How far along the top-left-to-bottom-right diagonal does this element appear?
  const [location, setLocation] = useState(-1);
  useEffect(() => {
    const rect = baseRef.current.getBoundingClientRect();
    const center = [rect.left + rect.width / 2, rect.top + rect.height / 2 + window.scrollY];
    const distance = Math.sqrt(center[0] ** 2 + (center[1] * 3) ** 2);
    const fullDiagonal = Math.sqrt(window.innerWidth ** 2 + (window.innerHeight * 3) ** 2);
    setLocation(Math.max(Math.min(distance / fullDiagonal, 1)), 0);
  }, []);

  // Given this element’s location, impose the necessary delay for elements that appear further
  // along the diagonal.
  useEffect(() => imposeDelay(location, imposedDelay), [location, imposedDelay, imposeDelay]);

  // What should the delay be at this location?
  const delayHere = getDelay(location);

  // Let the element come in once everything is worked out
  useEffect(() => {
    if (location > -1) setHidden(false);
  }, [location, setHidden]);

  const El = motion[el];
  const defaultTransition = {
    duration,
    ease: cubicBezier(0.25, 1, 0.5, 1),

    delay: delayHere,
    delayChildren: delayHere,
  };

  return (
    <El
      animate={hide ? "hidden" : "visible"}
      initial={false}
      transition={extendTransition ? extendTransition(defaultTransition) : defaultTransition}
      variants={variants}
      ref={baseRef}
      {...props}
    >
      <StaggeredBuildContext.Provider value={delayHere}>{children}</StaggeredBuildContext.Provider>
    </El>
  );
});

StaggeredBuildEffect.propTypes = {
  el: PropTypes.string,
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  imposedDelay: PropTypes.number,
  transition: PropTypes.object,
  variants: PropTypes.shape({
    hidden: PropTypes.object.isRequired,
    visible: PropTypes.object.isRequired,
  }),
};

export default StaggeredBuildEffect;
