import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";

import SectionColorProvider from "@components/layout/SectionColor";
import { GlobalVimeoPlayer } from "@components/Video";
import { PageTransitionProvider } from "@components/transition";
import { PlaylistProvider } from "@components/WorkShowcase/playlist";

import Cursor from "@components/Cursor";
import Nav from "@components/layout/Nav";
import Footer from "@components/layout/Footer";

import "./styles/index.scss";
import styles from "./BaseLayout.module.scss";

export default function Layout({ children, location, pageContext }) {
  const [contentOpacity, setContentOpacity] = useState(1);
  const searchData = useStaticQuery(graphql`
    query {
      allContentfulCaseStudy {
        nodes {
          ...ProjectOverview
        }
      }
    }
  `);

  return (
    <>
      <SectionColorProvider>
        <PlaylistProvider>
          <GlobalVimeoPlayer>
            <Cursor />
            <div className={styles.base}>
              <div className={styles.container}>
                <PageTransitionProvider
                  pathname={location.pathname}
                  effectivePathname={pageContext.effectivePathname || location.pathname}
                  forcedContentOpacity={contentOpacity}
                  // Content (some content is persistent and appears outside of the “transition zone”)
                  preChildren={
                    <Nav setContentOpacity={setContentOpacity} searchData={searchData} />
                  }
                  children={children} // eslint-disable-line react/no-children-prop
                  postChildren={<Footer />}
                />
              </div>
            </div>
          </GlobalVimeoPlayer>
        </PlaylistProvider>
      </SectionColorProvider>
    </>
  );
}
