/* Displays an inline video preview that (optionally) can be clicked to be expanded into a full
 * screen Vimeo embed.
 * When multiple InlineVideos are grouped inside a single MultiVideoConductor, the
 * MultiVideoConductor selects only one video's preview to play based on scroll position.
 */

import React, { useRef, useMemo, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { MultiVideoContext } from "./Conductor";
import { VideoPlayerContext } from "./Player";
import { uniqueId } from "lodash";

import { css } from "@oddcommon/utils";
import styles from "./InlineVideo.module.scss";

export default function InlineVideo({
  src, // source for inline video
  poster, // poster when the video isn't playing
  vimeoId, // video ID on vimeo into which the inline video can expand when clicked
  pause, // should the video stay paused (independent of any conductor)?
  cover, // should the video cover its container (true) or keep its aspect ratio (false)?
  className, // add a class name to the base element
  style, // add inline styles to the base element
  alt,
  webp,
  awards, // array of award objects
  ...props // pass any other props to base element
}) {
  const id = useMemo(() => uniqueId(), []);
  const videoRef = useRef();
  const conductor = useContext(MultiVideoContext);
  const playerContext = useContext(VideoPlayerContext);
  const conductorWantsPause = conductor ? conductor.currentlyPlayingId !== id : false;
  const videoPlaying = !conductorWantsPause && !pause && playerContext.isNotOpen;

  // Register this video with the orchestrator
  useEffect(() => {
    if (conductor) {
      conductor.register(id, videoRef);
      return () => conductor.deregister(id);
    }
  }, [id, conductor?.registry]); // eslint-disable-line react-hooks/exhaustive-deps

  const posterFadeResolveRef = useRef();

  // Pause the video when it shouldn't play
  useEffect(() => {
    // Try playing
    if (videoPlaying) {
      try {
        if (videoRef.current.paused) {
          const promise = videoRef.current.play();
          if (promise) promise.catch(() => {});
        }
      } catch {}
    }
    // Pause
    else {
      if (!poster) videoRef.current.pause();
      else {
        new Promise((resolve) => {
          posterFadeResolveRef.current = resolve;
        }).then(() => {
          posterFadeResolveRef.current = null;
          videoRef.current.pause();
        });
      }
    }
  }, [videoPlaying, poster]);

  return (
    <>
      <motion.div
        // Dim videos that were paused by conductor (not user-paused videos)
        animate={{
          opacity: !videoPlaying && !pause ? 0.7 : 1,
        }}
        style={style}
        // If vimeoId is passed this becomes expandable
        {...(vimeoId && {
          "data-cursor-cta": "play",
          onClick: () => {
            playerContext.actions.loadVideo(vimeoId);
            playerContext.actions.open();
          },
          onMouseEnter: () => playerContext.actions.loadVideo(vimeoId),
          style: { cursor: "pointer", ...style },
        })}
        {...props}
        className={css(styles.base, cover && styles.cover, className)}
      >
        {/* “Preview” video (autoplays inline, and can be expanded to the full video) */}
        <video src={src} loop muted playsInline ref={videoRef} {...props} />

        {/* Adds overlay if awards object is specified */}
        {awards && (
          <div className={css(styles.awards, styles[`awards--num${awards.length}`])}>
            {awards.map((award) => (
              <img
                key={award.name}
                className={styles.award}
                src={award.image.file.url + "?fm=webp&w=400&q=80"}
                alt={award.image.description ?? award.name}
              />
            ))}
          </div>
        )}

        {/* Cover image (displays when video is not playing) */}
        {poster && (
          <motion.picture
            animate={
              videoPlaying
                ? { opacity: 0, transition: { duration: 0.35, ease: "easeIn" } }
                : { opacity: 1, transition: { duration: 0.35, ease: "easeOut" } }
            }
            initial={false}
            // If we finish fading in the poster on pause, report that
            onAnimationComplete={() => {
              if (!videoPlaying) posterFadeResolveRef.current?.();
            }}
          >
            {webp && <source type="image/webp" srcSet={webp} />}
            <img src={poster + "?fm=webp&w=1920&q=80"} alt={alt}></img>
          </motion.picture>
        )}
      </motion.div>
    </>
  );
}

InlineVideo.propTypes = {
  src: PropTypes.string.isRequired,
  poster: PropTypes.string,
  vimeoId: PropTypes.number,

  pause: PropTypes.bool,
  cover: PropTypes.bool,
};
InlineVideo.defaultProps = {
  pause: false,
  cover: false,
};
