import React, { useEffect } from "react";

import BodyStyler from "./BodyStyler";

import { useSectionColor } from "./SectionColorProvider";

export default function InitialColors({ backgroundColor, accentColor, isDark }) {
  const {
    globalProviderReady,
    actions: { setPageBackgroundColor, setPageAccentColor, setPageIsDark },
  } = useSectionColor();

  useEffect(() => {
    setPageBackgroundColor(backgroundColor);
    setPageAccentColor(accentColor);
    setPageIsDark(isDark);
  }, [
    accentColor,
    backgroundColor,
    isDark,
    setPageAccentColor,
    setPageBackgroundColor,
    setPageIsDark,
  ]);

  return globalProviderReady ? null : (
    <BodyStyler
      styles={{
        pageBackgroundColor: backgroundColor,
        pageAccentColor: accentColor,
        pageIsDark: isDark,
      }}
    />
  );
}
