/*
 * Orchestrates the timing of build-in animations. Enables animations to register timing info to
 * a delay on subsequent build-in animations, in order to create more natural timing overall.
 */

import React, { useRef, useCallback, createContext } from "react";

import { uniqueId } from "lodash";
import { transform } from "framer-motion";

export const StaggerTimingContext = createContext();

export default function StaggerTimingProvider({ children }) {
  // Keep track of which delays child components have asked to impose on subsequent items
  const registeredDelays = useRef([]);
  const imposeDelay = useCallback((location, delay) => {
    const id = uniqueId();
    registeredDelays.current = [...registeredDelays.current, { delay, location, id }];
    registeredDelays.current.sort((a, b) => a.location - b.location);
    // cleanup function provided to easily deregister when something needs to change
    return () => {
      registeredDelays.current = registeredDelays.current.filter(({ id }) => id !== id);
    };
  }, []);

  const getDelay = useCallback((searchLocation) => {
    let staggerDelay = 0;
    const applicableDelays = registeredDelays.current.filter(
      ({ location: delayLocation }) => delayLocation < searchLocation,
    );
    applicableDelays.forEach(({ delay, location }) => {
      // At its actual location, a delay doesn't apply at all. 5% after its location, it applies fully.
      staggerDelay += transform(searchLocation, [location, location + 0.05], [0, delay]);
    });

    const baseDelay = 0.5;
    return baseDelay + staggerDelay;
  }, []);

  return (
    <StaggerTimingContext.Provider
      value={{
        imposeDelay,
        getDelay,
      }}
    >
      {children}
    </StaggerTimingContext.Provider>
  );
}
