import { useEffect, useMemo, useRef } from "react";
import { useMotionValue, MotionValue } from "framer-motion";
import sync, { cancelSync } from "framesync";

export const lerp = (from, to, alpha) => from + (to - from) * alpha;
export function lerper({ from, to, alpha = 0.1, restDelta = 0.1 }) {
  // In order to mimic the popmption spring API, we return an objcet that has a start() function
  // This start function can be called with an onUpdate callback function
  return {
    start(onUpdate) {
      let position = from;

      // Schedule updates to happen once per frame
      const process = sync.update(({ delta: timeDelta }) => {
        const framesElapsed = timeDelta / (1000 / 60); // number of 60fps frames since last update
        // Perform lerp based on how many frames have passed
        const effectiveAlpha = 1 - (1 - alpha) ** framesElapsed;
        position = lerp(position, to, effectiveAlpha);
        // if we're within restDelta of the final position, go to the final position & stop updates
        if (Math.abs(to - position) <= restDelta) {
          position = to;
          cancelSync.update(process);
        }
        onUpdate(position);
      }, true);

      // Return an object that has a stop() function
      return {
        stop() {
          cancelSync.update(process);
        },
      };
    },
  };
}

// Based on the useSpring implementation included in framer-motion
// From a MotionValue, creates a MotionValue smoothed out with linear interpolation
export function useLerp(source, config = {}) {
  const activeLerpAnimation = useRef(null);
  // This is the MotionValue on which our updates will be based
  // source can be either a number or a motionvalue
  const lerpedValue = useMotionValue(source instanceof MotionValue ? source.get() : source);

  useMemo(() => {
    // "attach" attaches a "passive effect" to the MotionValue, which intercepts calls to "set"
    // If 'value' has a "passive effect" attached, calls to 'set' will call that attached effect
    // function, instead of directly updating the value.
    // Only one function is attached at a time, so there's no need to unattach before useMemo runs
    lerpedValue.attach((value, setFunc) => {
      // this is the "effect function"
      // If there's a lerp animation running, stop it so that it won't continue to send conflicting
      // updates
      if (activeLerpAnimation.current) activeLerpAnimation.current.stop();
      // Attach a new lerp animation
      activeLerpAnimation.current = lerper({
        from: lerpedValue.get(),
        to: value,
        ...config,
      }).start(setFunc); // the animation should call the setFunc with the new value as it goes
    });
    // we only need to redefine what the effect function is when the config changes
  }, Object.values(config)); // eslint-disable-line react-hooks/exhaustive-deps

  // If the source value is a MotionValue, we need to trigger our internal motionValue to update
  // whenever the source value updates.
  useEffect(() => {
    if (source instanceof MotionValue) {
      return source.onChange((v) => lerpedValue.set(parseFloat(v)));
    }
    return undefined;
  }, [source, lerpedValue]);

  return lerpedValue;
}
