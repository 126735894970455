import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import PauseIcon from "@assets/icons/material-pause.svg";
import PlayIcon from "@assets/icons/material-play-arrow.svg";

import { css } from "@oddcommon/utils";
import styles from "./Controls.module.scss";

// Display a timestamp from a given number of seconds
function formatTime(secs) {
  const minutes = Math.floor(secs / 60);
  const seconds = Math.floor(secs % 60);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}

export default function PlayerControls({
  // data
  duration,
  currentTime,
  loadedTime,
  isLoading,
  isPlaying,
  // controls
  togglePlay,
  seek,
}) {
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    let timeout = null;
    const onMouseMove = () => {
      setInactive(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setInactive(true);
      }, 1500);
    };
    window.addEventListener("mousemove", onMouseMove);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <>
      {isLoading && <div className={styles.loading}>Loading</div>}
      <div
        className={css(styles.base, isLoading && styles.forceCover, inactive && styles.inactive)}
        onClick={(e) => {
          if (e.target.classList.contains(styles.base)) togglePlay();
        }}
      >
        <div className={styles.content}>
          {!isLoading && (
            <div className={styles.playPause} onClick={togglePlay}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </div>
          )}
          <div className={styles.controlsBar}>
            <span className={styles.timestamp}>{formatTime(currentTime)}</span>
            <div
              className={styles.progressElements}
              style={{
                "--progress": `${(currentTime / duration) * 100}%`,
              }}
            >
              <progress
                className={styles.loadProgress}
                value={Math.min(loadedTime, duration)}
                max={Math.floor(duration)}
              />
              {/* <label 
                htmlFor="progress" 
                style={{
                  transform: `translateX(${(currentTime / duration) * 100}%)`
                }}
              >
                {currentTime}
              </label> */}
              <input
                name="progress"
                id="progress"
                type="range"
                min="0"
                max={duration}
                value={currentTime}
                step="0.01"
                onChange={(e) => seek(e.target.value)}
              />
            </div>
            <span className={styles.timestamp}>-{formatTime(duration - currentTime)}</span>
          </div>
        </div>
      </div>
    </>
  );
}

PlayerControls.propTypes = {
  duration: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  loadedTime: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,

  togglePlay: PropTypes.func.isRequired,
  seek: PropTypes.func.isRequired,
};
