/* The conductor directs multiple Videos such that only one has its preview playing at a time */

import React, { useState, useRef, useEffect, createContext } from "react";

export const MultiVideoContext = createContext();

export default function MultiVideoConductor({ children }) {
  // which of the inline videos is currently playing?
  const [currentlyPlayingId, setCurrentlyPlayingId] = useState(null);

  const registeredVideos = useRef([]);

  useEffect(() => {
    // Choose exactly one of the registered videos to have its preview playing
    const chooseVideo = () => {
      if (!registeredVideos.current.length) return;
      // How close is each video to the vertical center of the video? (lower is better)
      const scoreVideo = ({ inlineVideoRef }) => {
        const { top, height } = inlineVideoRef.current.getBoundingClientRect();
        const verticalCenter = top + height / 2;
        return Math.abs(window.innerHeight / 2 - verticalCenter);
      };
      const scoredVideos = registeredVideos.current.map((video) => ({
        ...video,
        score: scoreVideo(video),
      }));
      setCurrentlyPlayingId(scoredVideos.sort((a, b) => a.score - b.score)[0].id);
    };
    chooseVideo();
    window.addEventListener("scroll", chooseVideo, { passive: true });
    window.addEventListener("resize", chooseVideo, { passive: true });
    return () => {
      window.removeEventListener("scroll", chooseVideo);
      window.removeEventListener("resize", chooseVideo);
    };
  }, [setCurrentlyPlayingId]);

  return (
    <MultiVideoContext.Provider
      value={{
        currentlyPlayingId,
        registry: registeredVideos,
        register(id, inlineVideoRef) {
          registeredVideos.current.push({ id, inlineVideoRef });
        },
        deregister(searchId) {
          registeredVideos.current = registeredVideos.current.filter(({ id }) => id !== searchId);
        },
      }}
    >
      {children}
    </MultiVideoContext.Provider>
  );
}
