import React from "react";
import { Link } from "gatsby";

import styles from "./SearchGrid.module.scss";

const SearchGrid = ({ items }) => {
  const MAX_ITEMS = 24;

  return (
    <div className={styles.container}>
      {items.map((item, i) => {
        if (i + 1 > MAX_ITEMS) return null;
        return (
          <article
            key={i}
            className={styles.item}
            data-cursor-color={item.accentColor}
            style={{ "--page-accent-color": item.accentColor }}
          >
            <Link to={`/work/project/${item.slug}`} className={styles.imageContainer}>
              <img
                className={styles.image}
                src={item.thumbnail?.poster + "?fm=webp&w=600&q=80"}
                alt={item.title}
              />
            </Link>
            <div className={styles.content}>
              <h3 className={styles.title}>{item.title}</h3>
              <p className={styles.client}>{item.client}</p>
            </div>
            <div>
              <Link className={styles.link} to={`/work/project/${item.slug}`}>
                View Project
              </Link>
            </div>
          </article>
        );
      })}
    </div>
  );
};

export default SearchGrid;
