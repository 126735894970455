import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

export const getBodyStyles = ({
  pageBackgroundColor,
  pageAccentColor,
  bgTransitionTime,
  pageIsDark,
}) => {
  const textColor = typeof pageIsDark !== "undefined" && (pageIsDark ? "light" : "dark");
  return {
    ...(bgTransitionTime && { "--page-background-transition-time": `${bgTransitionTime}s` }),
    ...(pageBackgroundColor && { "--page-background-color": pageBackgroundColor }),
    ...(pageAccentColor && { "--page-accent-color": pageAccentColor }),
    ...(textColor && {
      "--is-dark": pageIsDark ? 1 : 0,
      "--color-text-primary": `var(--color-${textColor}-text-primary)`,
      "--color-text-secondary": `var(--color-${textColor}-text-secondary)`,
      "--color-text-hint": `var(--color-${textColor}-text-hint)`,
      color: "var(--color-text-primary)",
    }),
  };
};

export default function BodyStyler({ styles: passedStyles }) {
  const styles = getBodyStyles(passedStyles);
  const stylesString = Object.entries(styles)
    .map(([k, v]) => k.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`) + `: ${v}`)
    .join("; ");

  return (
    <Helmet
      bodyAttributes={{
        // react-helmet wants a string on the client but an object for SSR
        style: typeof window === "undefined" ? styles : stylesString,
      }}
    />
  );
}
BodyStyler.propTypes = {
  styles: PropTypes.shape({
    pageBackgroundColor: PropTypes.string,
    pageAccentColor: PropTypes.string,
    bgTransitionTime: PropTypes.number,
    pageIsDark: PropTypes.bool,
  }).isRequired,
};
