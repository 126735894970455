export const socialLinks = [
  { name: "Vimeo", url: "https://vimeo.com/bgstr" },
  { name: "Twitter", url: "https://twitter.com/bigstarnyc" },
  { name: "Facebook", url: "https://www.facebook.com/BigStar-109272860715020/" },
  { name: "LinkedIn", url: "https://www.linkedin.com/company/bigstar/" },
  { name: "Instagram", url: "https://www.instagram.com/bgstr/" },
];

export const navLinks = [
  { name: "Work", path: "/work/" },
  { name: "About", path: "/about/" },
  { name: "Spotlight", path: "/spotlight/" },
  { name: "Careers", path: "/careers/" },
  { name: "Contact", path: "/contact/" },
];
