import { animate } from "popmotion";

const defaultAnimation = {
  type: "spring",
  stiffness: 500,
  damping: 100,
  mass: 5,
  restDelta: 0.05,
  restSpeed: 0.1,
};

export default function animateScroll(targetScrollPosition, animation = defaultAnimation) {
  const scrollAnimation = { current: null };

  scrollAnimation.current = animate({
    from: window.scrollY,
    to: targetScrollPosition,
    ...animation,
    onUpdate(value) {
      window.scrollTo(0, value);
    },
    onComplete() {
      scrollAnimation.current = null;
      window.removeEventListener("wheel", cancelAnimatedScroll);
      window.removeEventListener("touchmove", cancelAnimatedScroll);
    },
  });

  const cancelAnimatedScroll = () => {
    if (scrollAnimation.current) scrollAnimation.current.stop();
  };

  window.addEventListener("wheel", cancelAnimatedScroll);
  window.addEventListener("touchmove", cancelAnimatedScroll);
  return () => {};
}
