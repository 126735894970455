import React, { useRef, useCallback, useMemo } from "react";

/*
 * useColorMeasure gives you a function to measure CSS color values as an RGBA string, and an
 * element that you can put at the place in the DOM where you want to measure CSS variable values.
 */
export function useColorMeasurer() {
  const measureColorElement = useRef();
  const measureColor = useCallback((color) => {
    if (measureColorElement.current) {
      measureColorElement.current.style.backgroundColor = color;
      const measured = getComputedStyle(measureColorElement.current).backgroundColor || "";
      const rgbValues = measured
        .match(/rgb\(([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3})\)/i)
        ?.slice?.(1);
      if (rgbValues?.length === 3) return [...rgbValues.map(parseFloat), 1];
      const rgbaValues = measured
        .match(/rgba\(([0-9]{1,3}),\s*([0-9]{1,3}),\s*([0-9]{1,3}),\s*([01]\.?[0-9]*)\)/i)
        ?.slice?.(1);
      if (rgbaValues?.length === 4) return rgbaValues.map(parseFloat);
    }
    console.warn(`Warning: failed to measure color ${color}`);
    return [0.0, 0.0, 0.0, 0.0];
  }, []);

  return useMemo(
    () =>
      Object.assign(measureColor, {
        probe() {
          return <div style={{ display: "none" }} ref={measureColorElement} />;
        },
        rgbaString(color) {
          const [r, g, b, a] = measureColor(color);
          return `rgba(${r}, ${g}, ${b}, ${a})`;
        },
      }),
    [measureColor],
  );
}
