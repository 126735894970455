import React, { useContext, useState, useRef, useCallback, useEffect } from "react";

import { motion, useMotionValue, useSpring, transform, useTransform } from "framer-motion";
import { cubicBezier, mix, mixColor } from "popmotion";
import { useLerp } from "@js-utils/framer-motion-lerp";

import { useSectionColor } from "@components/layout/SectionColor";
import { VideoPlayerContext } from "@components/Video/Player";

import { useColorMeasurer } from "@js-utils/hooks";
import styles from "./Cursor.module.scss";

const spring = {
  type: "spring",
  stiffness: 350,
  damping: 25,
  restDelta: 0.001,
  restSpeed: 0.1,
};

// Recursively search parent nodes for cursor-related attributes
function getCursorAttrs(target) {
  if (!target) return {};
  const parentAttrs = getCursorAttrs(target.parentNode);
  // tail recursion ensures child nodes take precedence
  const cursorAttrs = Object.fromEntries(
    // only data-cursor-* attributes
    Object.entries(target.dataset || {}).filter(([k]) => /^cursor[A-Z]/.test(k)),
  );
  return { ...parentAttrs, ...cursorAttrs };
}

export default function Cursor() {
  const { isOpen } = useContext(VideoPlayerContext);

  const [text, setText] = useState(null);
  const expanded = !!text;
  const [dragMode, setDragMode] = useState(false);
  const [hidden, setHidden] = useState(true);
  const fullscreen = false;

  // The last truthy value that text held
  // Used to hold on to text labels during transitions
  const [lastText, setLastText] = useState(text);
  useEffect(() => {
    if (text) setLastText(text);
  }, [text]);

  // Track the transparent version of the real color by measuring the RGBA values and making it
  // transparent
  const [rgbaColor, setRgbaColor] = useState("rgba(0, 0, 0, 0)");
  const [transparentColor, setTransparentColor] = useState("rgba(0, 0, 0, 0)");
  // MotionValues to reflect the current accent color (both opaque and clear)
  const tweenedRgbaColor = useSpring(rgbaColor, spring);
  const tweenedTransparentColor = useSpring(transparentColor, spring);
  tweenedRgbaColor.set(rgbaColor);
  tweenedTransparentColor.set(transparentColor);

  const measureColor = useColorMeasurer();
  // Function to update cursor color from any CSS value
  const updateColorFrom = useCallback(
    (color) => {
      const [r, g, b, a] = measureColor(color);
      setRgbaColor(`rgba(${r}, ${g}, ${b}, ${a})`);
      setTransparentColor(`rgba(${r}, ${g}, ${b}, 0)`);
    },
    [measureColor],
  );

  const updateCursorProperties = useCallback(
    (currentTarget) => {
      const attrs = getCursorAttrs(currentTarget);
      if (attrs.cursorColor) updateColorFrom(attrs.cursorColor);
      else updateColorFrom("var(--page-accent-color)");
      if (attrs.cursorCta) setText(attrs.cursorCta);
      else setText(null);
      // drag mode: when the user is dragging an element that has data-cursor-drag-mode, the cursor
      // moves faster so that it doesn't trail behind the element being dragged
      if (attrs.cursorDragMode) setDragMode(true);
      else setDragMode(false);
    },
    [updateColorFrom],
  );

  // In “drag mode,” the cursor follows the mouse more closely when the mouse is down so that
  // the trailing effect doesn't make dragging look “off”
  const [mouseDown, setMouseDown] = useState(false);
  useEffect(() => {
    const down = () => setMouseDown(true);
    const up = () => setMouseDown(false);
    document.addEventListener("mousedown", down);
    document.addEventListener("mouseup", up);
    return () => {
      document.removeEventListener("mousedown", down);
      document.removeEventListener("mouseup", up);
    };
  });

  const vw = typeof window === "undefined" ? 0 : window.innerWidth / 100;
  const vh = typeof window === "undefined" ? 0 : window.innerHeight / 100;

  // Detect touch devices on first load
  const [isTouch, setIsTouch] = useState(true);
  useEffect(() => {
    setIsTouch(window.matchMedia("(pointer: coarse)").matches);
  }, []);

  const trailSpeed = 0.2;
  let appliedTrailSpeed = expanded ? trailSpeed : 1 - (1 - trailSpeed) / 1.3;
  if (dragMode && mouseDown) appliedTrailSpeed = 0.9;
  const x = useLerp(vw * 50, { alpha: appliedTrailSpeed });
  const y = useLerp(vh * 50, { alpha: appliedTrailSpeed });

  const hideTimeout = useRef();
  // Update the properties of the cursor based on the current mouse position
  const update = useCallback(() => {
    updateCursorProperties(document.elementFromPoint(x.get(), y.get())); // yay! love this API
    setTimeout(() => updateCursorProperties(document.elementFromPoint(x.get(), y.get())), 50);
    setHidden(false);
    // hide custom cursor mouse a second after last movement
    if (hideTimeout.current) clearTimeout(hideTimeout.current);
    hideTimeout.current = setTimeout(() => {
      setHidden(true);
      hideTimeout.current = null;
    }, 1500);
  }, [updateCursorProperties, x, y]);

  // Keep cursor X and Y positions updated
  useEffect(() => {
    const onMouseMove = (e) => {
      x.set(e.clientX);
      y.set(e.clientY);
      update(e);
    };
    if (!isTouch) {
      window.addEventListener("mousemove", onMouseMove, { passive: true });
      window.addEventListener("scroll", update, { passive: true });
      window.addEventListener("click", update, { passive: true });
      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("scroll", update);
        window.removeEventListener("click", update);
      };
    } else {
      x.set(vw * 50);
      y.set(vh * 50);
      window.addEventListener("scroll", update, { passive: true });
      window.addEventListener("click", update, { passive: true });
      return () => {
        window.removeEventListener("scroll", update);
        window.removeEventListener("click", update);
      };
    }
  }, [x, y, update, isTouch, vw, vh]);

  // When the cursor goes “fullscreen” it should cover the whole width of the screen
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  useEffect(() => {
    const resize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    resize();
    window.addEventListener("resize", resize, { passive: true });
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [setWindowWidth, setWindowHeight]);

  // untransformed dimensions of cursor
  const baseSize = 120;
  const baseInnerRadius = baseSize / 2;

  const hiddenScale = 0.01;
  const defaultScale = 0.15;
  const draggingScale = 0.85;
  const expandedScale = 1;
  // In “fullscreen” mode, the inner radius of the circle needs to be able to fill the diagonal of
  // the scren
  const fullscreenScale = Math.sqrt(windowWidth ** 2 + windowHeight ** 2) / baseInnerRadius;

  const currentScale = useMotionValue();

  // Correct backdrop blur size for scale
  const backdropFilter = useTransform(currentScale, (scale) => {
    const targetBlurSize = transform(
      scale,
      [defaultScale, expandedScale, fullscreenScale],
      [0.75, 5, 7.5],
    );
    return `blur(${targetBlurSize / scale}px)`;
  });
  // Find a color to use to fade page elements towards the page background color
  // Update when the value of the page background color changes
  const { pageBackgroundColor } = useSectionColor();
  const [pageFadeColor, setPageFadeColor] = useState("rgba(0, 0, 0, 0.8)");
  useEffect(() => {
    const [r, g, b] = measureColor(pageBackgroundColor);
    setPageFadeColor(`rgba(${r}, ${g}, ${b}, 0.8)`);
  }, [measureColor, pageBackgroundColor]);

  // Determine which cursor variant to use
  let state = "default";
  if (fullscreen) {
    state = "fullscreen";
  } else if (dragMode && mouseDown) {
    state = "dragging";
  } else if (expanded) {
    state = "expanded";
    // hidden can only occur when not expanded or fullscreen
  } else if (hidden || isTouch) {
    state = "hidden";
  }

  if (isOpen) state = "hidden";
  const [lastState, setLastState] = useState(state);

  // double check cursor attributes after state change so that we catch the proper cursor state
  // after exiting fullscreen mode
  useEffect(() => {
    setTimeout(() => updateCursorProperties(document.elementFromPoint(x.get(), y.get())), 50);
  }, [state, updateCursorProperties, x, y]);

  const defaultTransition = lastState !== "fullscreen" ? spring : { ...spring, damping: 35 };

  return (
    <>
      <motion.div
        className={styles.cursor}
        variants={{
          hidden: {
            scale: hiddenScale,
            opacity: 0,
            transition: defaultTransition,
          },
          default: {
            scale: defaultScale,
            opacity: 1,
            transition: defaultTransition,
          },
          expanded: {
            scale: expandedScale,
            opacity: 1,
            transition: defaultTransition,
          },
          dragging: {
            scale: draggingScale,
            opacity: 1,
            transition: defaultTransition,
          },
          fullscreen: {
            scale: fullscreenScale,
            opacity: 1,
            transition: {
              type: "tween",
              ease: cubicBezier(0.5, 0, 0.75, 0),
              duration: 0.5,
            },
          },
        }}
        style={{
          width: baseSize,
          height: baseSize,
          x,
          y,
          // Colors can't be defined inside variants because they need special behavior to avoid
          // springing past targets
          backgroundColor: useTransform(
            currentScale,
            [
              defaultScale,
              expandedScale,
              expandedScale * 1.2,
              mix(expandedScale * 1.2, fullscreenScale, 0.25),
            ],
            [
              "rgba(150, 150, 150, 0.3)",
              "rgba(0, 0, 0, 0.1)",
              "rgba(0, 0, 0, 0.1)",
              // when fullscreen, use a transparent version of the page background color to simulate
              // fading the elements on the page
              pageFadeColor,
            ],
          ),
          boxShadow: useTransform(
            [currentScale, tweenedRgbaColor, tweenedTransparentColor],
            ([scale, baseColor, clearColor]) => {
              // clear shadow when in 'default' mode; colorful shadow when in expanded mode or bigger
              const color = transform(
                scale,
                [
                  defaultScale,
                  expandedScale,
                  expandedScale * 1.2,
                  mix(expandedScale * 1.2, fullscreenScale, 0.25),
                  mix(expandedScale * 1.2, fullscreenScale, 0.65),
                  fullscreenScale,
                ],
                [
                  clearColor,
                  baseColor,
                  baseColor,
                  // Mostly clear between 25% and 65% fullscreen
                  mixColor(clearColor, baseColor)(0.25),
                  mixColor(clearColor, baseColor)(0.15),
                  // Almost fully clear when fullscreen
                  mixColor(clearColor, baseColor)(0.05),
                ],
              );
              const spread = 2.5 / ((scale - 1) / 5 + 1); // correct a little for scale but not too much
              return `0 0 0 ${spread}px ${color}`;
            },
          ),
          // Correct for scale
          WebkitBackdropFilter: backdropFilter,
          backdropFilter,
        }}
        animate={state}
        initial={false}
        transformTemplate={(_, transform) => `translate(-50%, -50%) ${transform}`}
        onUpdate={({ scale }) => currentScale.set(scale)}
        onAnimationComplete={(variantName) => {
          setLastState(variantName);
        }}
      >
        <motion.span
          variants={{
            hidden: { opacity: 0 },
            default: { opacity: 0 },
            expanded: { opacity: 1 },
            fullscreen: { opacity: 0 },
          }}
          style={{
            scale: useTransform(currentScale, (scale) => Math.min(1 / scale, 1)),
          }}
          transition={spring}
        >
          {lastText}
        </motion.span>
      </motion.div>
      <measureColor.probe />
    </>
  );
}
