/* The Player plays a single video from Vimeo at a time, filling the whole screen. */

import React, { useState, useRef, useEffect, createContext } from "react";

import { motion } from "framer-motion";
import { cubicBezier } from "popmotion";

import VimeoPlayer from "@vimeo/player";
import PlayerControls from "./Controls";

import CloseIcon from "@assets/icons/material-close.svg";

import { css } from "@oddcommon/utils";
import styles from "./Player.module.scss";

const videoOptions = {
  responsive: true,
  playsinline: true,
  controls: false,
};

export const VideoPlayerContext = createContext();

export default function GlobalVimeoPlayer({ children }) {
  const [wantsOpen, setWantsOpen] = useState(false);
  const wantsOpenRef = useRef(wantsOpen);
  wantsOpenRef.current = wantsOpen;
  const [finishedState, setFinishedState] = useState(false);
  // Is it open at all? (including mid-transition in either direction)
  // wantsOpen when on its way to being open; finishedState visible when on its way to being closed
  const isOpen = wantsOpen || finishedState === "visible";
  // Is it not open at all? (including mid-transition in either direction)
  // finishedState is not visible when on its way to being open but not yet there; wantsOpen is
  // false when on its way to being closed.
  // NOTE: !isNotOpen != isOpen because both are true in the transition states!
  const isNotOpen = finishedState !== "visible" || !wantsOpen;

  useEffect(() => {
    if (isOpen) document.scrollingElement.style.setProperty("overflow", "hidden");
    else document.scrollingElement.style.removeProperty("overflow");
  }, [isOpen]);

  const videoContainerRef = useRef();
  const playerRef = useRef();

  const [currentId, setCurrentId] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [loadedTime, setLoadedTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const initPlayer = async (id) => {
    playerRef.current = new VimeoPlayer(videoContainerRef.current, { ...videoOptions, id });
    await playerRef.current.setVolume(1);
    // Close player when video ends
    playerRef.current.on("ended", () => setWantsOpen(false));
    // Keep track of video progress
    playerRef.current.on("durationchange", ({ duration }) => setVideoDuration(duration));
    playerRef.current.on("timeupdate", ({ seconds }) => setCurrentTime(seconds));
    playerRef.current.on("progress", ({ seconds }) => setLoadedTime(seconds));
    playerRef.current.on("playing", () => setIsPlaying(true));
    playerRef.current.on("pause", () => setIsPlaying(false));
    playerRef.current.on("bufferstart", () => setIsLoading(true));
    playerRef.current.on("bufferend", () => setIsLoading(false));
  };

  const loadVideo = async (id) => {
    if (!id || (playerRef.current && id === (await playerRef.current.getVideoId()))) return;
    if (!playerRef.current) {
      initPlayer(id);
    } else {
      await playerRef.current.loadVideo(id);
    }
    setCurrentId(id);
  };
  const startVideo = async (id) => {
    setIsLoading(true);
    await loadVideo(id);
    if ((await playerRef.current.getCurrentTime()) > 0) await playerRef.current.setCurrentTime(0);
    setCurrentTime(0);
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      if (!wantsOpenRef.current) return;
      setIsLoading(false);
      await playerRef.current.play();
    } catch (e) {}
  };

  const open = async (videoId) => {
    setWantsOpen(true);
    await startVideo(videoId);
  };
  const close = async () => {
    if (playerRef.current) {
      await Promise.race([
        playerRef.current.pause(),
        new Promise((resolve) => setTimeout(resolve, 250)),
      ]);
    }
    setWantsOpen(false);
  };

  useEffect(() => {
    const escListener = ({ key }) => {
      if (key === "Escape") close();
    };
    document.addEventListener("keydown", escListener);
    return () => document.removeEventListener("keydown", escListener);
  }, []);

  return (
    <VideoPlayerContext.Provider
      value={{
        isOpen,
        isNotOpen,
        currentId,
        actions: {
          loadVideo,
          open,
          close,
        },
      }}
    >
      {children}

      <motion.div
        className={css(styles.container)}
        onClick={(e) => {
          if (e.target.classList.contains(styles.container)) close();
        }}
        initial="hidden"
        animate={wantsOpen ? "visible" : "hidden"}
        variants={{
          hidden: {
            pointerEvents: "none",
            scaleY: 0,
            transition: {
              duration: 0.5,
              ease: cubicBezier(0.32, 0, 0.67, 0),
              delay: 0.2,
            },
          },
          visible: {
            pointerEvents: "all",
            scaleY: 1,
            transition: {
              duration: 0.5,
              ease: cubicBezier(0.33, 1, 0.68, 1),
              delayChildren: 0.65,
            },
          },
        }}
        onAnimationComplete={(variantName) => setFinishedState(variantName)}
      >
        {/* Needs to be a separate element instead of a background on the container because we need
            to set opacity on it (we can't trivially transparentize page background color) */}
        <motion.div className={styles.background} />

        <motion.button
          className={styles.closeButton}
          onClick={close}
          variants={{
            hidden: { opacity: 0, transition: { duration: 0.15, ease: "easeIn" } },
            visible: { opacity: 1, transition: { duration: 0.3, ease: "easeOut" } },
          }}
        >
          Close
          <CloseIcon />
        </motion.button>

        <motion.div
          ref={videoContainerRef}
          className={styles.videoContainer}
          variants={{
            hidden: { scale: 0.95, opacity: 0, transition: { duration: 0.15, ease: "easeIn" } },
            visible: { scale: 1, opacity: 1, transition: { duration: 0.3, ease: "easeOut" } },
          }}
          transformTemplate={(_, transform) => `translate(-50%, -50%) ${transform}`}
        >
          <PlayerControls
            // Data
            duration={videoDuration}
            currentTime={currentTime}
            loadedTime={loadedTime}
            isLoading={isLoading}
            isPlaying={isPlaying}
            // Controls
            togglePlay={() => {
              if (isPlaying) playerRef.current.pause();
              else playerRef.current.play();
            }}
            seek={async (time) => {
              playerRef.current.setCurrentTime(time);
            }}
          />
        </motion.div>
      </motion.div>
    </VideoPlayerContext.Provider>
  );
}
