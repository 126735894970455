// During route transitions, make sure that the page doesn't jump to the top before we fade out

let currentY = 0;

exports.onRouteUpdate = () => {
  window.scrollTo(0, currentY);
};

exports.onPreRouteUpdate = () => {
  currentY = window.scrollY;
};
