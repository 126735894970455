import React from "react";
import StaggeredBuildEffect from "../StaggeredBuildEffect";

export default function FadeUp({ children, ...props }) {
  return (
    <StaggeredBuildEffect
      variants={{
        hidden: { y: 25, opacity: 0 },
        visible: { y: 0, opacity: 1 },
      }}
      {...props}
    >
      {children}
    </StaggeredBuildEffect>
  );
}
