import React, { useState, useEffect, useRef } from "react";
import { globalHistory } from "@reach/router";
import * as Dialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { css } from "@oddcommon/utils";

import CloseIcon from "@assets/icons/material-close.svg";
import SearchGrid from "@components/layout/SearchModal/SearchGrid";
import SearchIcon from "@assets/icons/search.svg";
import { ProjectOverview } from "@js-utils/contentful/casestudy";

import styles from "./SearchModal.module.scss";

export default function SearchModal({ data, openSearchFromMobile, setOpenSearchFromMobile }) {
  const searchRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [shownProjects, setShownProjects] = useState([]);
  const [currentPath, setCurrentPath] = useState(globalHistory.location.pathname);

  // on open, focus the search input. on close, clear the search input
  const setOpenChange = (open) => {
    if (!open) {
      setSearchQuery("");
    } else {
      requestAnimationFrame(() => {
        if (searchRef?.current) {
          searchRef.current.focus();
        }
      });
    }
    setOpen(open);
  };

  // on route change, close the modal
  useEffect(() => {
    if (globalHistory.location.pathname !== currentPath) {
      setOpen(false);
      setCurrentPath(globalHistory.location.pathname);
      setTimeout(() => {
        setSearchQuery("");
      }, 300);
    }
  }, [globalHistory.location.pathname]);

  // on search query change, filter the shown projects
  useEffect(() => {
    if (searchQuery.length) {
      const allProjects = data.allContentfulCaseStudy.nodes
        .filter((p) => !p.spotlight_page)
        .map((project) => {
          return ProjectOverview(project);
        });
      const filteredData = allProjects.filter(({ title, client, capability }) => {
        const capabilityNames = (capability || []).map((c) => c.title);
        const searchable = [title, client, ...capabilityNames];
        return searchable.some((key) => key.toLowerCase().includes(searchQuery.toLowerCase()));
      });
      setShownProjects(filteredData);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (openSearchFromMobile) {
      setOpenChange(true);
      setOpenSearchFromMobile(false);
    }
  }, [openSearchFromMobile]);

  return (
    <Dialog.Root open={open} onOpenChange={setOpenChange}>
      <Dialog.Trigger className={styles.trigger}>
        <span className={styles.triggerDesktop}>
          <SearchIcon />
          Search
        </span>
        <span className={styles.triggerMobile}>Search</span>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className={css(styles.overlay, open && styles["overlay--open"])} />
        <Dialog.Content className={styles.content}>
          <VisuallyHidden.Root asChild>
            <Dialog.Title>Search</Dialog.Title>
          </VisuallyHidden.Root>
          <VisuallyHidden.Root asChild>
            <Dialog.Description>Type something to begin your search</Dialog.Description>
          </VisuallyHidden.Root>
          <div className={css(styles.contentContainer, "search-modal__content-container")}>
            <Dialog.Close className={styles.close}>
              <CloseIcon />
            </Dialog.Close>
            <div
              className={styles.searchInputContainer}
              onClick={() => {
                searchRef.current.focus();
              }}
            >
              <input
                type="text"
                ref={searchRef}
                className={styles.searchInput}
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className={styles.searchResultsContainer}>
              <div className={styles.searchResults} aria-live>
                {!searchQuery.length && <p className={styles.noResults}>Type above to search</p>}
                {searchQuery.length > 0 && shownProjects.length > 0 && (
                  <SearchGrid items={shownProjects} />
                )}
                {searchQuery.length > 0 && shownProjects.length === 0 && (
                  <p className={styles.noResults}>No results found</p>
                )}
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
