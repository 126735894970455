import React, { useState, useMemo, useRef, useContext, useEffect } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import StaggeredBuildEffect, { StaggeredBuildContext } from "../StaggeredBuildEffect";

import styles from "./TextMask.module.scss";
import { cubicBezier } from "popmotion";

export default function TextMask({ children, ...props }) {
  // Split words and the space between them into separate elements in an array
  const wordsAndSpaces = useMemo(() => children.split(/(\s+)/g), [children]);
  // Split letters and chunks of whitespace into separate elements in an array, and label them
  const lettersAndSpaces = useMemo(
    () =>
      wordsAndSpaces.flatMap((wordOrSpace, i) => {
        // it's a space
        if (i % 2) return { type: "space", value: wordOrSpace };
        return wordOrSpace.split("").map((letter) => ({ type: "letter", value: letter }));
      }),
    [wordsAndSpaces],
  );

  // Measure kerning to compensate for breaking it
  // Note: we can fix kerning, but ligatures are irreparably broken inside this component

  const baseRef = useRef();

  const [kernings, setKernings] = useState([]);
  useEffect(() => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    (async () => {
      await document.fonts.ready;
      // Draw letter pairs on a canvas to figure out their kerning
      const kernings = lettersAndSpaces.map((letterOrSpace, i) => {
        // Only operate on pairs of adjacent letters (no need to measure kerning against whitespace)
        if (letterOrSpace.type !== "letter") return;
        const next = lettersAndSpaces[i + 1];
        if (next?.type !== "letter") return;
        // The kerning we need to apply is the difference between the combined width of the letters
        // together, and the width of the letters separately
        let letter = letterOrSpace.value;
        let nextLetter = next.value;
        ctx.font = getComputedStyle(baseRef.current).font;
        const unkernedWidth = ctx.measureText(letter).width + ctx.measureText(nextLetter).width;
        const kernedWidth = ctx.measureText(`${letter}${nextLetter}`).width;
        return kernedWidth / unkernedWidth - 1;
      });
      setKernings(kernings);
    })();
  }, [lettersAndSpaces]);

  // Figure out animation timing

  const numLetters = lettersAndSpaces.filter(({ type }) => type === "letter").length;
  const numWords = lettersAndSpaces.filter(({ type }) => type === "space").length + 1;

  // time a letter takes to fully enter
  const childDuration = 0.9;
  // time between letters entering
  const letterStagger = (0.12 * childDuration * Math.sqrt(numLetters / 5)) / numLetters;
  // Extra time between words
  const wordStagger = letterStagger * 6;

  // whole length of the animation - max stagger plus
  const totalDuration = letterStagger * numLetters + wordStagger * (numWords - 1) + childDuration;
  // Delay to add to everything

  let letterIndex = -1;
  let wordIndex = 0;

  const Letter = ({ value, kerning, staggerBy }) => {
    const baseDelay = useContext(StaggeredBuildContext);
    return (
      <span
        className={styles.window}
        style={{ marginRight: kernings ? `${kerning - 0.2}em` : `-0.2em` }}
      >
        <motion.span
          style={{ display: "inline-block" }}
          variants={{
            hidden: { y: "80%", opacity: 0 },
            visible: { y: 0, opacity: 1 },
          }}
          transition={{
            duration: childDuration,
            delay: baseDelay + staggerBy,
            ease: cubicBezier(0.22, 1, 0.36, 1),
          }}
        >
          {value}
        </motion.span>
      </span>
    );
  };

  return (
    <StaggeredBuildEffect
      el="span"
      duration={totalDuration}
      imposedDelay={totalDuration * 0.4} // longer delay than normal to emphasize a more dramatic effect
      ref={baseRef}
      {...props}
    >
      {lettersAndSpaces
        .map(({ type, value }, i) => {
          // Wrap letters in two spans (one as a clipping box, one to move the letter within it)
          if (type === "letter") {
            letterIndex++;
            return (
              <Letter
                key={i}
                value={value}
                staggerBy={letterStagger * letterIndex + wordStagger * wordIndex}
                kerning={kernings[i]}
              />
            );
          }
          // Leave blocks of whitespace as-is
          else if (type === "space") {
            wordIndex++;
            return value;
          }
        })
        // Group all contiguous letters (i.e. those that don't have whitespace in between) into
        // array groups
        .reduce(
          (accum, el) => {
            if (React.isValidElement(el)) {
              // Splice this element into the end of the final word in the sequence
              return [...accum.slice(0, -1), [...accum[accum.length - 1], el]];
            } else {
              return [...accum, el, []];
            }
          },
          [[]],
        )
        // Render wrapped words
        .map((el, i) => {
          // words
          if (Array.isArray(el))
            return (
              <span className={styles.word} key={i}>
                {el}
              </span>
            );
          // whitespace
          return el;
        })}
    </StaggeredBuildEffect>
  );
}
TextMask.propTypes = {
  children: PropTypes.string,
};
