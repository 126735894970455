import React, { useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";

import { throttle } from "lodash";
import { useSpring } from "framer-motion";
import { easeOut } from "popmotion";

import Star from "@components/Star";

export default function FaviconDriver({ color: passedColor, transitionTime }) {
  // Render <Star /> component to get SVG markup for favicon.
  // Include a special key so that the color can be replaced without re-rendering the component
  const iconTemplate = useMemo(() => {
    const icon = <Star spec={{ size: "1rem", stroke: "0.125rem" }} stroke={"{{REPLACE_COLOR}}"} />;
    return ReactDOMServer.renderToStaticMarkup(icon);
  }, []);

  // Get data URI for a given favicon color
  const getIconURI = useCallback(
    (color) => {
      // Replace the special {{REPLACE_COLOR}} key to get a working SVG icon for a given color
      const icon = iconTemplate.replace("{{REPLACE_COLOR}}", color);
      // Data URL is required for favicon
      return `data:image/svg+xml,${encodeURIComponent(icon)}`;
    },
    [iconTemplate],
  );

  // Set favicon color
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setFaviconColor = useCallback(
    throttle((color) => {
      let link = document.getElementById("dynamic-fav");
      if (!link) {
        link = document.createElement("link");
        link.id = "dynamic-fav";
        link.setAttribute("rel", "icon");
        link.setAttribute("type", "image/svg+xml");
        link.setAttribute("sizes", "any");
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.setAttribute("href", getIconURI(color));
    }, 1000 / 30),
    [getIconURI],
  );

  // Animate between colors
  const colormv = useSpring(passedColor, {
    duration: transitionTime * 1000,
    ease: easeOut,
  });
  colormv.set(passedColor);
  useEffect(() => {
    setFaviconColor(colormv.get());
    return colormv.onChange((v) => setFaviconColor(v));
  }, [colormv, setFaviconColor]);

  return null;
}

FaviconDriver.propTypes = {
  color: PropTypes.string.isRequired,
  transitionTime: PropTypes.number.isRequired,
};
