import { useCallback } from "react";

// Takes a theme from Contentful & formats to our data model
export const FormatTheme = (theme) => {
  if (theme) {
    const isCustom = theme.backgroundColor.indexOf("#") > -1;

    let backgroundColor = isCustom
      ? theme.backgroundColor
      : `var(--color-${theme.backgroundColor.toLowerCase()})`;
    backgroundColor.split(" ").join("-");

    let accentColor = isCustom
      ? theme.accentColor
      : `var(--color-${theme.accentColor.toLowerCase()})`;
    accentColor.split(" ").join("-");

    let isDark = theme.theme == "Light" ? false : true;

    return {
      backgroundColor: backgroundColor,
      accentColor: accentColor,
      isDark: isDark,
    };
  } else {
    return {
      backgroundColor: `var(--color-black)`,
      accentColor: `var(--color-red)`,
      isDark: true,
    };
  }
};

export const ProjectOverview = (project) => {
  return {
    slug: project.slug,
    title: project.title.title.split("\n").join(" "),
    description: project.summary.summary,
    accentColor: FormatTheme(project.theme).accentColor,
    client: project.client.clientName,
    thumbnail: { ...parseHero(project.hero) },
    capability: project.capability,
    awards: project.awards,
  };
};

export const Quote = (data) => {
  return {
    type: "quote",
    id: data.id,
    attribution: {
      name: data.author,
      title: data.role,
    },
    quote: data.quote?.quote,
    ...FormatTheme(data.theme),
  };
};

export const Video = (data) => {
  return {
    type: "video",
    id: data.id,
    src: data.previewSrc?.file.url || data.src,
    poster: data.staticPreview?.file.url,
    vimeoId: parseInt(data.src),
  };
};

export const ImageGrid = (data) => {
  return {
    type: "image-grid",
    id: data.id,
    attribution: data.attribution,
    layout: data.layout?.toLowerCase(),
    images: data.images.map((image) => {
      return {
        id: image.id,
        src: image.file.url,
        type: image.file.contentType,
        alt: image.description,
      };
    }),
  };
};

export const TextBlock = (data) => {
  return {
    type: "text",
    id: data.id,
    label: data.label,
    layout: data.layout?.toLowerCase(),
    content: data.content.childMarkdownRemark.html,
  };
};

export const Image = (data) => {
  return {
    type: "image",
    id: data.id,
    src: data.src,
    alt: data.alt,
    attribution: data?.attribution,
  };
};

export const Carousel = (data) => {
  const carousel = {
    type: "carousel",
    id: data.id,
    items: [],
  };
  data.items.forEach((item, index) => {
    const type = item.source ? "image" : "video";
    switch (type) {
      case "image":
        carousel.items.push(
          Image({
            id: index,
            src: item.source?.file.url,
            alt: item.source?.description,
            attribution: item?.attribution,
          }),
        );
        break;
      case "video":
        carousel.items.push(Video({ id: index, src: item.previewSrc?.file.url }));
        break;
    }
  });

  return carousel;
};

export const TextAndImageBlock = (key, data) => {
  return {
    type: "text-and-image",
    id: data.id,
    layout: data.layout ? data.layout.toLowerCase().replace("text ", "") : null,
    textLabel: data.TextAndImageContent.label,
    text: data.TextAndImageContent.text.childMarkdownRemark.html,
    image: Image({
      type: data.TextAndImageContent.image.source?.file.contentType,
      id: data.TextAndImageContent.image?.id,
      src: data.TextAndImageContent.image.source?.file.url,
      alt: data.TextAndImageContent.image.source?.description,
      attribution: data.TextAndImageContent.image?.attribution,
    }),
  };
};

const resolveTextAndImage = (data, index) => {
  return {
    id: data.DoubleTextContent[index].id,
    textLabel: data.DoubleTextContent[index].label,
    text: data.DoubleTextContent[index].text.childMarkdownRemark.html,
    image: Image({
      id: data.DoubleTextContent[index].image.id,
      src: data.DoubleTextContent[index].image.source?.file.url,
      alt: data.DoubleTextContent[index].image.source?.description,
      attribution: data.DoubleTextContent[index].image?.attribution,
    }),
  };
};
export const DoubleTextAndImageBlock = (data) => {
  return {
    type: "double-text-and-image",
    id: data.id,
    layout: data.layout?.toLowerCase().split(" ").join("-"),
    first: data.DoubleTextContent[0] ? resolveTextAndImage(data, 0) : null,
    second: data.DoubleTextContent[1] ? resolveTextAndImage(data, 1) : null,
  };
};

export const Blocks = (section) => {
  let blocks = [];
  if (!section.blocks) return blocks;
  section.blocks.forEach((block) => {
    switch (block.__typename) {
      case "ContentfulVideo":
        blocks.push(Video(block));
        break;
      case "ContentfulImage":
        blocks.push(
          Image({
            id: block.id,
            src: block.source?.file.url,
            alt: block.source?.description,
            attribution: block?.attribution,
            type: block.source?.file.contentType,
          }),
        );
        break;
      case "ContentfulTextBlock":
        blocks.push(TextBlock(block));
        break;
      case "ContentfulImageGridBlock":
        blocks.push(ImageGrid(block));
        break;
      case "ContentfulCarousel":
        blocks.push(Carousel(block));
        break;
      case "ContentfulTextAndImageBlock":
        blocks.push(TextAndImageBlock("TextAndImageContent", block));
        break;
      case "ContentfulDoubleTextAndImage":
        blocks.push(DoubleTextAndImageBlock(block));
        break;
    }
  });

  return blocks;
};

export const ProjectCredits = (data) => {
  let credits = [];
  data.forEach((project) => {
    credits.push({
      name: project.title,
      credits: project.people.map((p) => {
        return { name: p.name, credit: p.title };
      }),
    });
  });
  return credits;
};

const parseHero = (hero) => {
  let _type = hero.__typename;
  switch (_type) {
    case "ContentfulImage":
      return Image({
        id: hero.id,
        src: hero.source?.file.url,
        alt: hero.source?.description,
      });
    case "ContentfulVideo":
      return Video(hero);
  }
};

// Parses a case study
export function useContentfulCaseStudy() {
  return useCallback((project) => {
    const baseTheme = FormatTheme(project.theme);
    const hero = {
      ruleLabel: project.ruleLabel,
      title: project.title.title,
      client: project.client.clientName,
      description: project.description?.description?.html,
      summary: project.summary.summary,
      hero: {
        type: parseHero(project.hero).type,
        content: parseHero(project.hero),
        alt: parseHero(project.hero).alt,
      },
      backgroundColor: baseTheme.backgroundColor,
      accentColor: baseTheme.accentColor,
      isDark: baseTheme.isDark,
    };
    const credits = {
      description: project.credits?.description?.childMarkdownRemark.html,
      projects: project.credits ? ProjectCredits(project.credits.projects) : null,
      theme: project.credits ? FormatTheme(project.credits.theme) : null,
    };
    const sections = [];
    project.sections?.forEach((section) => {
      let theme = section.theme ? FormatTheme(section.theme) : baseTheme;
      switch (section.__typename) {
        case "ContentfulQuote":
          sections.push(Quote(section));
          break;
        default:
          sections.push({
            type: "freeform",
            id: section.id,
            title: section.title,
            ruleLabel: section.eyebrow,
            description: section.description?.description?.html,
            ...theme,
            blocks: Blocks(section),
          });
          break;
      }
    });

    const relatedProjects = {
      projects: project.relatedProjects?.map((project) => {
        return ProjectOverview(project);
      }),
      ...baseTheme,
    };

    return {
      hero,
      sections,
      credits,
      relatedProjects,
    };
  }, []);
}
