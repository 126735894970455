import React, { useState, useRef, useEffect, createContext, useContext } from "react";
import { useLocation as useRawLocation } from "@reach/router";
import { useSectionColor } from "@components/layout/SectionColor";
import { motion, useAnimation } from "framer-motion";

import StaggerTimingProvider from "./StaggerTimingProvider";

// Trim a single trailing slash from a string
const trimSlash = (str) => str.replace(/\/$/, "");

const PageTransitionContext = createContext();

// A “stable” useLocation that won't change at the wrong time during page transitions
export const useLocation = () => useContext(PageTransitionContext).location;

export default function PageTransitionProvider({
  preChildren,
  children,
  postChildren,

  pathname,
  effectivePathname,
  forcedContentOpacity = 1,
}) {
  const loc = useRef();
  loc.current = { ...useRawLocation() };

  // Hold on to children and location so that we can “freeze” them during transitions
  const [appliedChildren, setAppliedChildren] = useState(children);
  const [appliedLocation, setAppliedLocation] = useState(loc.current);

  // Used to fade content in and out imperatively
  const contentAnimationControls = useAnimation();
  const contentHiddenRef = useRef(true);

  const [previousEffectivePathname, setPreviousEffectivePathname] = useState(effectivePathname);
  useEffect(() => {
    (async () => {
      // Run a full page transition only when the “effective pathname” changes
      if (trimSlash(previousEffectivePathname) !== trimSlash(effectivePathname)) {
        setPreviousEffectivePathname(effectivePathname);
        // Fade content out
        contentHiddenRef.current = true;
        contentAnimationControls.stop();
        await contentAnimationControls.start({
          opacity: 0,
          transition: { duration: 0.35, ease: "easeIn" },
        });
        // Scroll to top of page
        window.scrollTo(0, 0);
        await new Promise((resolve) => setTimeout(resolve, 200));
        // Let new content come in
        setAppliedChildren(children);
      }
      // Update the applied location even if we didn't run a page transition
      setAppliedLocation(loc.current);
    })();
  }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  // Once the new children have arrived, let the page background color update and let the page
  // content fade back in
  const { actions: sectionColorActions } = useSectionColor();
  const forcedContentOpacityRef = useRef();
  forcedContentOpacityRef.current = forcedContentOpacity;
  useEffect(() => {
    sectionColorActions.refresh();
    contentHiddenRef.current = false;
    contentAnimationControls.set({ opacity: forcedContentOpacityRef.current });
  }, [appliedChildren, sectionColorActions, contentAnimationControls]);

  // Respond to forcedContentOpacity changes by fading the page content
  useEffect(() => {
    if (!contentHiddenRef.current) {
      contentAnimationControls.start({
        opacity: forcedContentOpacity,
        transition: { duration: 0.35, ease: "easeInOut" },
      });
    }
  }, [forcedContentOpacity, contentAnimationControls]);

  return (
    <PageTransitionContext.Provider
      value={{
        location: appliedLocation,
      }}
    >
      <StaggerTimingProvider>
        {preChildren}
        <motion.div animate={contentAnimationControls}>
          {/* Keep our own value for children so that we can handle transitions out/in */}
          {appliedChildren}
        </motion.div>
        {postChildren}
      </StaggerTimingProvider>
    </PageTransitionContext.Provider>
  );
}
