import React from "react";
import StaggeredBuildEffect from "../StaggeredBuildEffect";

export default function RuleWipe({ children, ...props }) {
  return (
    <StaggeredBuildEffect
      duration={1.85}
      imposedDelay={0.25}
      variants={{
        hidden: { opacity: 0, "--collapse-amount": 1 },
        visible: { opacity: 1, "--collapse-amount": 0 },
      }}
      {...props}
    >
      {children}
    </StaggeredBuildEffect>
  );
}
