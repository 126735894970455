import React, { useState, useEffect } from "react";

import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { cubicBezier } from "popmotion";

import { Link } from "gatsby";
import { useLocation } from "@components/transition/PageTransitionProvider";

import Wordmark from "@assets/icons/wordmark.svg";
import MenuIcon from "@assets/icons/material-menu.svg";
import CloseIcon from "@assets/icons/material-close.svg";
import SearchModal from "@components/layout/SearchModal/SearchModal";

import animateScroll from "@js-utils/animate-scroll";
import { navLinks } from "@config";

import { css } from "@oddcommon/utils";
import styles from "./Nav.module.scss";

// Should a click event result in in-page navigation (false if it opens in new tab, etc.)?
// https://github.com/reach/router/blob/d2c9ad06715c9d48c2d16f55f7cd889b626d2521/src/index.js#L648-L651
const shouldNavigate = (event) =>
  !event.defaultPrevented &&
  event.button === 0 &&
  !(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

// Trim a single trailing slash from a string
const trimSlash = (str) => str.replace(/\/$/, "");

/* Scrolls to to the top of the page if its route already matches, otherwise acts as a normal <Link /> */
const NavLink = ({ children, to, onClick }) => {
  const { pathname } = useLocation();
  const isCurrent = trimSlash(pathname) === trimSlash(to);
  const isPartiallyCurrent = pathname.startsWith(trimSlash(to));
  if (isCurrent) {
    return (
      <a
        href={to}
        className={styles.active}
        onClick={(e) => {
          // make sure we can still cmd+tab to open in new tab
          if (shouldNavigate(e)) {
            e.preventDefault();
            animateScroll(0);
          }
          if (onClick) onClick(e);
        }}
      >
        {children}
        <motion.div className={styles.underline} layoutId="underline" />
      </a>
    );
  } else {
    return (
      <Link to={to} onClick={onClick}>
        {children}
        {isPartiallyCurrent && <motion.div className={styles.underline} layoutId="underline" />}
      </Link>
    );
  }
};

export default function Nav({ setContentOpacity, searchData }) {
  const [mobileSheetOpen, setMobileSheetOpen] = useState(false);
  const [openSearchFromMobile, setOpenSearchFromMobile] = useState(false);

  useEffect(() => {
    setContentOpacity(mobileSheetOpen ? 0.2 : 1);
  }, [mobileSheetOpen, setContentOpacity]);

  const makeLink = ({ name, path }) => (
    <li key={name}>
      <NavLink to={path} onClick={() => setMobileSheetOpen(false)}>
        {name}
      </NavLink>
    </li>
  );
  const links = navLinks.map(makeLink);

  return (
    <nav className={css(styles.base, styles.bar)}>
      <NavLink to="/">
        <Wordmark className={styles.logo} aria-label="BGSTR" />
      </NavLink>

      <AnimateSharedLayout>
        <AnimatePresence>
          <ul className={styles.desktopLinks}>
            {links}
            <SearchModal
              data={searchData}
              openSearchFromMobile={openSearchFromMobile}
              setOpenSearchFromMobile={setOpenSearchFromMobile}
            />
          </ul>
        </AnimatePresence>
      </AnimateSharedLayout>

      <button
        type="button"
        className={styles.mobileMenuTrigger}
        onClick={() => setMobileSheetOpen(true)}
      >
        <MenuIcon />
      </button>

      <AnimatePresence>
        {mobileSheetOpen && (
          <motion.div
            className={styles.scrim}
            onClick={(e) => {
              if (e.target.classList.contains(styles.scrim)) {
                setMobileSheetOpen(false);
              }
            }}
            variants={{
              visible: {
                "--presence": 1,
                pointerEvents: "all",
                transition: { ease: cubicBezier(0.4, 0.0, 0.2, 1), duration: 0.5 },
              },
              hidden: {
                "--presence": 0,
                pointerEvents: "none",
                transition: { ease: cubicBezier(0.4, 0.0, 0.2, 1), duration: 0.4 },
              },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <div className={styles.mobileSheet}>
              <div className={styles.bar}>
                <button
                  className={styles.mobileMenuTrigger}
                  onClick={() => setMobileSheetOpen(false)}
                >
                  <CloseIcon />
                </button>
              </div>
              <ul>
                {makeLink({ name: "Home", path: "/" })}
                {links}
                <button
                  onClick={() => {
                    setOpenSearchFromMobile(true);
                    setMobileSheetOpen(false);
                  }}
                >
                  Search
                </button>
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
}
